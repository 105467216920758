<template>
  <basic-container>
    <head-layout :head-title="$t('cip.plat.authority.dataScope.title.indexHeadTitle')"></head-layout>
    <grid-head-layout ref="searchFrom" :search-columns="searchColumns"v-on="onSearchEvent">
    </grid-head-layout>
    <grid-layout ref="gridLayOut" :tableOptions="option" :tableData="data" :table-loading="loading"
                 @gird-handle-select-click="selectionChange"
                 :gridRowBtn="gridRowBtn"
                 @scope_confBtn="handleDataScope"
                 @tree-load='treeLoad'
                 :page="page"
                 @page-size-change="onLoad"
                 @page-current-change="onLoad"
                 @page-refresh-change="onLoad"
    >
      <template #source="{ row, index }">
        <div style="text-align:center">
          <i :class="row.source"/>
        </div>
      </template>
    </grid-layout>
    <el-drawer  :visible.sync="drawerVisible"
                :direction="direction"
                append-to-body custom-class="wf-drawer"
                :before-close="handleDrawerClose"
                size="1000px">
      <basic-container>
        <head-layout :head-title="$t('cip.plat.authority.dataScope.title.indexHeadTitle')" :head-btn-options="headBtnOptions" @head-add="headAdd"></head-layout>
        <grid-head-layout ref="searchScopeFrom"
                          :search-columns="searchScopeColumns"
                          @grid-head-search="gridHeadSearch"
                          @grid-head-empty="gridHeadEmpty">
        </grid-head-layout>
        <grid-layout ref="crudScope"
                     :tableOptions="optionScope"
                     :tableData="dataScope"
                     :table-loading="scopeLoading"
                     :gridRowBtn="gridScopeRowBtn"
                     :page="pageScope"
                     @page-size-change="onLoadScope"
                     @page-current-change="onLoadScope"
                     @page-refresh-change="onLoadScope"

                     @row-del="rowDelScope"
                     @row-update="rowUpdate"
                     @row-save="rowSaveScope"
                     :before-open="beforeOpenScope"
                     @search-change="searchChangeScope"
                     @search-reset="searchResetScope"
                     @selection-change="selectionChangeScope"
                     @current-change="currentChangeScope"
                     @size-change="sizeChangeScope"
        ></grid-layout>
      </basic-container>
    </el-drawer>
    <CommonDialog v-if="showDialog" class="paperDialog" width="50%" dialogTitle="新增"
                  @confirm="rowUpdateScope" @cancel="cancel">
      <form-layout ref="formLayout" :column="optionScope.column" :dataForm.sync="formScope"></form-layout>
    </CommonDialog>
  </basic-container>
</template>

<script>
  import {
    add,
    remove,
    update,
    getLazyMenuList,
    getMenu
  } from "@/api/system/menu";
  import {
    addDataScope,
    removeDataScope,
    updateDataScope,
    getListDataScope,
    getMenuDataScope
  } from "@/api/system/scope";
  import {mapGetters} from "vuex";
  import iconList from "@/config/iconList";
  import func from "@/util/func";
  import CommonDialog from "@/components/CommonDialog/index.vue";
  import HeadLayout from "@/views/components/layout/head-layout.vue";
  import GridLayout from "@/views/components/layout/grid-layout.vue";
  import dialogHeadBtn from "@/views/components/layout/dialog-head-btn.vue";

  export default {
    components: {
      HeadLayout,
      GridLayout,
      dialogHeadBtn,
      CommonDialog
    },
    data() {
      return {
        form: {},
        addOrEdit: '',
        showDialog: '',
        selectionList: [],
        query: {},
        loading: true,
        parentId: 0,
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        drawerVisible: false,
        direction: 'rtl',
        scopeMenuId: 0,
        scopeMenuCode: '',
        scopeMenuName: "菜单",
        scopeLoading: false,
        menu: true,
        watchMode: true,
        searchColumns:[{
            prop: "name",
            search: true,
            placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.dataScope.field.name"),
          },
          {
            placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.dataScope.field.code"),
            prop: "code",
            search: true,
          }
        ],
        searchScopeColumns:[{
          prop: 'scopeName',
          search: true,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.scopeName"),
        },
          {
            placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.resourceCode"),
            prop: "resourceCode",
            search: true,
          }
        ],
        option: {
          lazy: true,
          tip: false,
          simplePage: true,
          searchShow: false,
          searchMenuSpan: 6,
          dialogWidth: "60%",
          tree: true,
          border: true,
          index: true,
          selection: true,
          viewBtn: false,
          editBtn: false,
          addBtn: false,
          delBtn: false,
          menuWidth: 200,
          dialogClickModal: false,
          column: [
            {
              label:this.$t('cip.plat.authority.dataScope.field.name'),
              prop: "name",
              search: true,
              align:'left',
              overHidden: true,
              width: 300,
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.dataScope.field.name"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t('cip.plat.authority.dataScope.field.path'),
              prop: "path",
              align:'left',
              overHidden: true,
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.dataScope.field.path"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.parentIds"),
              prop: "parentId",
              type: "tree",
              dicUrl: "/api/sinoma-system/menu/tree",
              hide: true,
              props: {
                label: "title"
              },
              overHidden: true,
              align:'center',
              rules: [
                {
                  required: false,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.dataScope.field.parentIds"),
                  trigger: "click"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.source"),
              prop: "source",
              // type: "icon",
              slot: true,
              width: 80,
              // iconList: iconList,
              overHidden: true,
              // align:'center',
              // rules: [
              //   {
              //     required: true,
              //     message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.dataScope.field.source"),
              //     trigger: "click"
              //   }
              // ]
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.code"),
              prop: "code",
              search: true,
              overHidden: true,
              width: 300,
              align:'left',
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.dataScope.field.code"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.category"),
              prop: "category",
              type: "radio",
              dicData: [
                {
                  label: this.$t("cip.plat.authority.dataScope.field.menu"),
                  value: 1
                },
                {
                  label: this.$t("cip.plat.authority.dataScope.field.button"),
                  value: 2
                }
              ],
              overHidden: true,
              width: 300,
              align:'left',
              hide: true,
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.dataScope.field.category"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.alias"),
              prop: "alias",
              overHidden: true,
              width: 300,
              align:'left',
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.dataScope.field.alias"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.action"),
              prop: "action",
              type: "radio",
              overHidden: true,
              width: 300,
              align:'center',
              dicData: [
                {
                  label: this.$t("cip.plat.authority.dataScope.field.toolBar"),
                  value: 0
                },
                {
                  label: this.$t("cip.plat.authority.dataScope.field.actionBar"),
                  value: 1
                },
                {
                  label: this.$t("cip.plat.authority.dataScope.field.toolActionBar"),
                  value: 2
                }
              ],
              hide: true,
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.dataScope.field.action"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.sorts"),
              prop: "sort",
              type: "number",
              minRows: 0,
              maxRows: 1000000000,
              align: 'right',
              width: 80,
              overHidden: true,
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.dataScope.field.sorts"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.isOpen"),
              prop: "isOpen",
              type: "radio",
              overHidden: true,
              dicData: [
                {
                  label: this.$t("cip.plat.authority.dataScope.field.no"),
                  value: 0
                },
                {
                  label: this.$t("cip.plat.authority.dataScope.field.yes"),
                  value: 1
                },
              ],
              hide: true
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.remarks"),
              prop: "remark",
              overHidden: true,
              type: "textarea",
              span: 24,
              minRows: 6,
              hide: true
            }
          ]
        },
        data: [],
        formScope: {},
        selectionListScope: [],
        pageScope: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        optionScope: {
          tip: false,
          searchShow: false,
          search: false,
          viewBtn: false,
          searchMenuSpan: 6,
          border: true,
          index: true,
          selection: false,
          menuWidth: 200,
          dialogWidth: 900,
          dialogClickModal: false,
          column: [
            {
              label: this.$t("cip.plat.authority.dataScope.field.scopeName"),
              labelWidth:120,
              prop: "scopeName",
              maxlength: 200,
              value: "",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.dataScope.field.scopeName"),
                trigger: "blur"
              }]
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.resourceCode"),
              labelWidth:120,
              prop: "resourceCode",
              maxlength: 200,
              search: true,
              width: 100,
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.dataScope.field.scopeName"),
                trigger: "blur"
              }]
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.scopeColumn"),
              labelWidth:120,
              prop: "scopeColumn",
              width: 130,
              maxlength: 200,
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.dataScope.field.scopeColumn"),
                trigger: "blur"
              }]
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.scopeType"),
              labelWidth:120,
              type: "select",
              dicUrl: "/api/sinoma-system/dict/dictionary?code=data_scope_type",
              props: {
                label: "dictValue",
                value: "dictKey"
              },
              dataType: "number",
              width: 140,
              prop: "scopeType",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.dataScope.field.scopeType"),
                trigger: "blur"
              }]
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.scopeField"),
              labelWidth:120,
              prop: "scopeField",
              span: 24,
              hide: true,
              maxlength: 200,
              value: "*",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.dataScope.field.scopeField"),
                trigger: "blur"
              }],
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.scopeClass"),
              labelWidth:120,
              prop: "scopeClass",
              span: 24,
              hide: true,
              maxlength: 200,
              rules: [{
                required: true,
                message: this.$t("cip.plat.authority.dataScope.msg.scopeClass"),
                trigger: "blur"
              }],
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.scopeValue"),
              labelWidth:120,
              prop: "scopeValue",
              span: 24,
              minRows: 5,
              type: "textarea",
              maxlength: 1500,
              display: true,
              hide: true,
            },
            {
              label: this.$t("cip.plat.authority.dataScope.field.remark"),
              labelWidth:120,
              prop: "remark",
              span: 24,
              maxlength: 200,
              hide: true,
            },
          ]
        },
        dataScope: []
      };
    },
    watch: {
      'formScope.scopeType'() {
        this.initScope();
      }
    },
    computed: {
      ...mapGetters(["permission"]),
      gridRowBtn() {
        let   buttonBtn = [];
        buttonBtn.push(
          {
            label:"权限配置",
            emit: "scope_confBtn",
            type: "text",
            icon: ""
          });
        return buttonBtn;
      },
      headBtnOptions() {
        return [
          {
            label: this.$t("cip.cmn.btn.addBtn"),
            emit: "head-add",
            btnOptType: "add",
          }
        ];
      },
      gridScopeRowBtn() {
        let   buttonBtn = [];
        buttonBtn.push(
          {
            label:"编辑",
            emit: "row-update",
            type: "text",
            icon: ""
          });
        buttonBtn.push(
          {
            label:"删除",
            emit: "row-del",
            type: "text",
            icon: ""
          });
        return buttonBtn;
      },
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.menu_add, false),
          viewBtn: this.vaildData(this.permission.menu_view, false),
          delBtn: this.vaildData(this.permission.menu_delete, false),
          editBtn: this.vaildData(this.permission.menu_edit, false)
        };
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      },
      scopeIds() {
        let ids = [];
        this.selectionListScope.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      },
      onSearchEvent() {
        return {
          'grid-head-search': this.searchChange,
          'grid-head-empty': this.searchReset
        }
      }
    },
    mounted(){
      this.onLoad(this.page)
    },
    methods: {
      cancel(){
        this.formScope = {}
        this.showDialog = false
      },
      headAdd() {
        this.addOrEdit = 'add';
        this.showDialog = true;
      },
      // 列表查询 add by steve
      gridHeadSearch(searchForm) {
        this.pageScope.currentPage = 1;
        this.onLoadScope(this.pageScope, searchForm);
      },
      // 查询重置 add by steve
      gridHeadEmpty(searchForm) {
        this.pageScope.currentPage = 1;
        this.onLoadScope(this.pageScope, searchForm);
      },
      initScope() {
        const scopeType = func.toInt(this.formScope.scopeType);
        const watchMode = this.watchMode;
        let column = "-", name = "暂无";
        if (scopeType === 1) {
          column = "-";
          name = this.$t("cip.plat.authority.dataScope.field.allVisible");
        } else if (scopeType === 2) {
          column = "create_user";
          name = this.$t("cip.plat.authority.dataScope.field.personallyVisible");
        } else if (scopeType === 3) {
          column = "create_dept";
          name = this.$t("cip.plat.authority.dataScope.field.deptVisible");
        } else if (scopeType === 4) {
          column = "create_dept";
          name = this.$t("cip.plat.authority.dataScope.field.deptAndChildVisible");
        } else if (scopeType === 5) {
          column = "";
          name = this.$t("cip.plat.authority.dataScope.field.custom");
        }
        this.$refs.crudScope.option.column.filter(item => {
          if (watchMode) {
            if (item.prop === "scopeName") {
              this.formScope.scopeName = `${this.scopeMenuName} [${name}]`;
            }
            if (item.prop === "resourceCode" && this.addOrEdit === 'add') {
              this.formScope.resourceCode = this.scopeMenuCode;
            }
            if (item.prop === "scopeColumn") {
              this.formScope.scopeColumn = column;
            }
          }
          if (item.prop === "scopeValue") {
            item.display = scopeType === 5;
          }
        });
      },
      // 菜单管理模块
      rowSave(row, done, loading) {
        add(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          done();
        }, error => {
          window.console.log(error);
          loading();
        });
      },
      rowUpdate(row) {
        this.addOrEdit = 'edit';
        this.showDialog = true
        this.formScope = row
/*        update(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          done();
        }, error => {
          window.console.log(error);
          loading();
        });*/
      },
      rowDel(row) {
        this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          });
      },
      searchReset() {
        this.query = {};
        this.parentId = 0;
        this.onLoad(this.page);
      },
      searchChange(params, done) {
        this.query = params;
        this.parentId = '';
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
        done();
      },
      selectionChange(list) {
        this.selectionList = list;
      },
      selectionClear() {
        this.selectionList = [];
        // this.$refs.crud.toggleSelection();
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
          return;
        }
        this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            // this.$refs.crud.toggleSelection();
          });
      },
      beforeOpen(done, type) {
        if (["edit", "view"].includes(type)) {
          getMenu(this.form.id).then(res => {
            this.form = res.data.data;
          });
        }
        done();
      },
      beforeClose(done) {
        this.formScope = {};
        done();
      },
      currentChange(currentPage) {
        this.page.currentPage = currentPage;
      },
      sizeChange(pageSize) {
        this.page.pageSize = pageSize;
      },
      refreshChange() {
        this.onLoad(this.page, this.query);
      },
      onLoad(page, params = {}) {
        this.loading = true;
        getLazyMenuList(this.parentId, Object.assign(params, this.query)).then(res => {
          this.data = res.data.data;
          this.loading = false;
          this.selectionClear();
        });
      },
      treeLoad(tree, treeNode, resolve) {
        const parentId = tree.id;
        getLazyMenuList(parentId).then(res => {
          resolve(res.data.data);
        });
      },
      // 数据权限模块
      handleDataScope(row) {
        this.drawerVisible = true;
        this.scopeMenuId = row.id;
        this.scopeMenuCode = row.code;
        this.scopeMenuName = row.name;
        this.onLoadScope(this.pageScope)
      },
      handleDrawerClose(hide) {
        hide();
      },
      rowSaveScope(row, done, loading) {
        row = {
          ...row,
          menuId: this.scopeMenuId,
        };
        this.$loading();
        addDataScope(row).then(() => {
          this.onLoadScope(this.pageScope);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          this.$loading().close();
          done();
        }, error => {
          window.console.log(error);
          loading();
        });
      },
      rowUpdateScope(row, index, done, loading) {
        row = {
          ...this.formScope,
          menuId: this.scopeMenuId,
        };
        this.$loading();
        updateDataScope(row).then(() => {
          this.onLoadScope(this.pageScope);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          this.$loading().close();
          done();
        }, error => {
          window.console.log(error);
          loading();
        });
      },
      rowDelScope(row) {
        this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        })
          .then(() => {
            return removeDataScope(row.id);
          })
          .then(() => {
            this.onLoadScope(this.pageScope);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          });
      },
      handleDeleteScope() {
        if (this.selectionListScope.length === 0) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
          return;
        }
        this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        })
          .then(() => {
            return removeDataScope(this.scopeIds);
          })
          .then(() => {
            this.onLoadScope(this.pageScope);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.$refs.crudScope.toggleSelection();
          });
      },
      beforeOpenScope(done, type) {
        if (["add"].includes(type)) {
          this.watchMode = true;
          this.initScope();
        }
        if (["edit", "view"].includes(type)) {
          this.watchMode = false;
          getMenuDataScope(this.formScope.id).then(res => {
            this.formScope = res.data.data;
          });
        }
        done();
      },
      searchResetScope() {
        this.onLoadScope(this.pageScope);
      },
      searchChangeScope(params, done) {
        this.onLoadScope(this.pageScope, params);
        done();
      },
      selectionChangeScope(list) {
        this.selectionListScope = list;
      },
      currentChangeScope(currentPage) {
        this.pageScope.currentPage = currentPage;
      },
      sizeChangeScope(pageSize) {
        this.pageScope.pageSize = pageSize;
      },
      onLoadScope(page, params = {}) {
      this.pageScope = page;
      this.scopeLoading = true;
      const values = {
        ...params,
        menuId: this.scopeMenuId,
      }
      getListDataScope(
        page.currentPage,
        page.pageSize,
        Object.assign(values, this.query)
      ).then(res => {
        const data = res.data.data;
        this.$refs.crudScope.page.total = data.total;
        this.dataScope = data.records;
        this.selectionListScope = [];
        this.scopeLoading = false;
      });
    },
    }
  };
</script>
<style lang="scss">
.wf-drawer {
  .el-drawer__header {
    padding: 10px !important;
    color: #333333;
    font-size: 25px;
    margin: 10px 0px 0px !important;
  }
  .el-drawer__body {
    padding: 10px !important;
    overflow: auto;
  }
}
</style>

